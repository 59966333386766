
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IEnvironment } from '@skychute/ui-models';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IEnvironment = {
    production: isEqual('true', 'true'),
    services: {
        data: {
          endpoint: 'https://production-hasura.projectreprod.dev.skychute.com.au',
          path: '/v1/graphql',
          logQueries: 'false'
        },
        aws: {
          UserPoolId: 'ap-southeast-2_zy2N6r886',
          AppClientId: '2j7rs3n2c1ea6qu428eal6usth',
          cloudUri: 'https://r23ps12sd8.execute-api.ap-southeast-2.amazonaws.com/dev',
        },
        analytics: {
            segment: {
                enabled: isEqual('true', 'true'),
                writeKey: 'CKazVWLN9swSOQAxq5aOOuUzu9MFuARF',
            },
        },
    },
    application: {
        version: '1.99.1',
        gitHead: '69453e8dcf0f09386fca463d4b5d9d98b50d45bb',
        branch: 'production',
        buildDate: 'November 9th 2024, 4:03:42 pm',
        buildNumber: '462',
    },
    stripe: {
        publicKey: 'pk_live_51JVQJzICXqe0hIJdEuHt3cCPWLQgHnYCXVcsc8kpXsJ3BuFKzIbDqI3YTNOuvzE3H29ZPPIj1LF14LGxTkoq3mZV00s3KG1Qac'
    },
    addressFinder: {
        key: 'RBHCYUV987D4XKNPT6FL',
        src: 'https://api.addressfinder.io/assets/v3/widget.js'
    },
    courier_push_client_key:'ODM2OTc0NWItODA5NS00OTI3LWE1ZTEtMWI0OGEyZjBkNjg0',
    novu_push_client_key:'z6bmJwqLEdgn',
};
